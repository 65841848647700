// src/apiConfig.js

const getApiUrl = () => {
    // Check the environment and set the API URL accordingly
    if (process.env.NODE_ENV === 'development') {
      console.log('Running in development mode');
      return process.env.REACT_APP_API_URL || 'http://localhost:5001';
    } else if (process.env.NODE_ENV === 'production') {
      console.log('Running in production mode');
      return process.env.REACT_APP_API_URL;
    }
  
    // Default fallback (optional)
    return 'http://localhost:3001';
  };
  
  const API_URL = getApiUrl();
  
  export default API_URL;
  