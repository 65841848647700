import React, { useState, useEffect } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import Header from './components/Header';
import SecondaryHeader from './components/SecondaryHeader';
import Login from './pages/Login';
import MyBrewery from './pages/MyBrewery';
import MyAccount from './pages/MyAccount';
import Settings from './pages/Settings';
import About from './pages/About';
import Footer from './components/Footer';
import axios from 'axios';
import API_URL from './apiConfig';

const App = () => {
  console.log('App started');
  const [user, setUser] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();

  // Fetch current user data
  const fetchUser = async () => {
    console.log('fetchUser started');
    try {
      console.log('try loop started');
      console.log(`Fetching user from: ${API_URL}/auth/current_user`);
      console.log(`API_URL: ${API_URL}`);
      console.log(`Fetching user from: ${API_URL}/auth/current_user`);

      const response = await axios.get(`${API_URL}/auth/current_user`, {
        withCredentials: true,
      });
      setUser(response.data || null);
      console.log('User data:', response.data);
    } catch (error) {
      console.log('Catch loop started');
      console.error('Error fetching user:', error);
      setUser(null);
    }
  };

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const loggedIn = query.get('loggedIn');

    if (loggedIn === 'true') {
      navigate('/', { replace: true });
      fetchUser();
    } else {
      fetchUser();
    }
  }, [location, navigate]);

  return (
    <>
      <Header user={user} setUser={setUser} />
      {user && <SecondaryHeader />}
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/my-brewery" element={<MyBrewery />} />
        <Route path="/my-account" element={<MyAccount />} />
        <Route path="/settings" element={<Settings />} />
        <Route path="/about" element={<About />} />
        <Route path="/" element={user ? <h2>Welcome, {user.first_name}!</h2> : <h2>Build No... 13</h2>} />
      </Routes>
      <Footer />
    </>
  );
};

export default App;
