import React from 'react';
import { AppBar, Toolbar, Button } from '@mui/material';
import { Link } from 'react-router-dom';

const SecondaryHeader = () => {
  return (
    <AppBar position="static" color="default">
      <Toolbar>
        <Button component={Link} to="/my-brewery">
          My Brewery
        </Button>
        <Button component={Link} to="/my-account">
          My Account
        </Button>
        <Button component={Link} to="/settings">
          Settings
        </Button>
      </Toolbar>
    </AppBar>
  );
};

export default SecondaryHeader;
