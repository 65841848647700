import React from 'react';
import { Box, Typography } from '@mui/material';

const About = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '80vh',
        textAlign: 'center',
        gap: 2,
      }}
    >
      <Typography variant="h4">About This App</Typography>
      <Typography variant="body1">
        This is a React app using Material UI for styling and React Router for navigation. It showcases a simple header,
        footer, and multiple pages including a login and about page.
      </Typography>
    </Box>
  );
};

export default About;
