import React from 'react';
import { AppBar, Toolbar, Typography, Button } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';

const Header = ({ user, setUser }) => {
  const navigate = useNavigate();
  
  const handleLogout = async () => {
    try {
      await axios.get(`http://ec2-18-234-186-94.compute-1.amazonaws.com:5001/auth/login`, { withCredentials: true });
      setUser(null);
      navigate('/login');
    } catch (error) {
      console.error('Logout error:', error);
    }
  };

  return (
    <AppBar position="static">
      <Toolbar>
        <Typography
          variant="h6"
          component={Link}
          to="/"
          sx={{ flexGrow: 1, color: 'inherit', textDecoration: 'none' }}
        >
          My React App
        </Typography>
        <Button color="inherit" component={Link} to="/about">
          About
        </Button>
        {user ? (
          <>
            <Typography variant="body1" sx={{ marginRight: '16px' }}>
              Hello, {user.first_name}
            </Typography>
            <Button color="inherit" onClick={handleLogout}>
              Logout
            </Button>
          </>
        ) : (
          <Button color="inherit" component={Link} to="/login">
            Login
          </Button>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default Header;
