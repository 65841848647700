import React, { useState, useEffect } from 'react';
import { Box, Typography, Button, Snackbar, Alert } from '@mui/material';
import axios from 'axios';

const MyBrewery = () => {
  const [userData, setUserData] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  const fetchUserData = async () => {
    try {
      const response = await axios.get(`http://ec2-18-234-186-94.compute-1.amazonaws.com:5001/auth/current_user`, {
        withCredentials: true,
      });
      setUserData(response.data);
    } catch (error) {
      console.error('Error fetching user data:', error);
    }
  };
  

  const handleCreateSubdomain = async () => {
    try {
      const response = await axios.post(
        `http://ec2-18-234-186-94.compute-1.amazonaws.com:5001/auth/create_subdomain`,
        { username: userData.username },
        { withCredentials: true }
      );
      setSnackbarMessage(response.data.message);
      setSnackbarOpen(true);
    } catch (error) {
      console.error('Error creating subdomain:', error);
      setSnackbarMessage('Failed to create subdomain');
      setSnackbarOpen(true);
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  useEffect(() => {
    fetchUserData();
  }, []);

  if (!userData) return <Typography>Loading...</Typography>;

  return (
    <Box sx={{ padding: '20px', maxWidth: '600px', margin: '0 auto', textAlign: 'center' }}>
      <Typography variant="h5" gutterBottom>
        Assign your Brewery Domain
      </Typography>
      <Typography variant="h6" sx={{ marginBottom: '20px' }}>
        {userData.username}.hasabrewery.com
      </Typography>
      <Button variant="contained" color="primary" onClick={handleCreateSubdomain}>
        Create Subdomain
      </Button>

      {/* Snackbar for feedback */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={4000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert onClose={handleSnackbarClose} severity="success" sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default MyBrewery;
