import React, { useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

// Use environment variable for API URL
const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:5001';

const Login = () => {
  const navigate = useNavigate();

  // Function to start Google OAuth login
  const handleGoogleLogin = () => {
    console.log(`Redirecting to Google OAuth: ${API_URL}/auth/google`);
    window.location.href = `${API_URL}/auth/google`;
  };

  // Check if the user is already logged in
  useEffect(() => {
    const fetchUser = async () => {
      try {
        console.log(`Fetching current user from: ${API_URL}/auth/current_user`);
        const response = await axios.get(`${API_URL}/auth/current_user`, {
          withCredentials: true,
        });
        if (response.data) {
          // User is logged in, redirect to the home page
          console.log('User is already logged in:', response.data);
          navigate('/');
        } else {
          console.log('No user logged in');
        }
      } catch (error) {
        console.error('Error fetching user:', error);
      }
    };

    fetchUser();
  }, [navigate]);

  return (
    <div style={{ textAlign: 'center', marginTop: '20vh' }}>
      <h2>Login</h2>
      <button onClick={handleGoogleLogin}>Sign in with Google</button>
    </div>
  );
};

export default Login;
