import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { TextField, Button, Box, Typography } from '@mui/material';
import API_URL from '../apiConfig';


const Settings = () => {
    const [userData, setUserData] = useState(null);
    const [editMode, setEditMode] = useState(false);
    const [formData, setFormData] = useState({
        username: '',
        brewery_name: '',
        country: '',
        state: '',
        city: '',
    });

    

    // Regex for validation
    const validPattern = /^[a-zA-Z0-9_-]*$/;

    // Fetch current user data
    const fetchUserData = async () => {
        try {
            const response = await axios.get(`${API_URL}/auth/current_user`, {
                withCredentials: true,
            });
            setUserData(response.data);
            setFormData({
                username: response.data.username || '',
                brewery_name: response.data.brewery_name || '',
                country: response.data.country || '',
                state: response.data.state || '',
                city: response.data.city || '',
            });
        } catch (error) {
            console.error('Error fetching user data:', error);
        }
    };

    // Handle form field changes with validation
    const handleChange = (e) => {
        const { name, value } = e.target;

        // Only validate username and brewery_name fields
        if (name === 'username' || name === 'brewery_name') {
            if (!validPattern.test(value)) {
                alert('Only English letters, numbers, dashes (-), and underscores (_) are allowed.');
                return;
            }
        }

        setFormData((prev) => ({ ...prev, [name]: value }));
    };

    // Save changes
    const handleSave = async () => {
        try {
            const response = await axios.put(`${API_URL}/auth/update_user`, formData, {
                withCredentials: true,
            });
            setUserData(response.data);
            setEditMode(false);
            alert('User data updated successfully!');
        } catch (error) {
            console.error('Error updating user data:', error);
            alert('Failed to update user data.');
        }
    };

    // Toggle edit mode
    const toggleEditMode = () => {
        setEditMode(!editMode);
    };

    useEffect(() => {
        fetchUserData();
    }, []);

    if (!userData) return <Typography>Loading...</Typography>;

    return (
        <Box sx={{ padding: '20px', maxWidth: '600px', margin: '0 auto' }}>
            <Typography variant="h4" gutterBottom>
                Settings
            </Typography>

            <TextField
                label="Username"
                name="username"
                value={formData.username}
                onChange={handleChange}
                fullWidth
                margin="normal"
                disabled={!editMode}
                placeholder="-"
            />
            <TextField
                label="Brewery Name"
                name="brewery_name"
                value={formData.brewery_name}
                onChange={handleChange}
                fullWidth
                margin="normal"
                disabled={!editMode}
                placeholder="-"
            />
            <TextField
                label="Country"
                name="country"
                value={formData.country}
                onChange={handleChange}
                fullWidth
                margin="normal"
                disabled={!editMode}
                placeholder="-"
            />
            <TextField
                label="State"
                name="state"
                value={formData.state}
                onChange={handleChange}
                fullWidth
                margin="normal"
                disabled={!editMode}
                placeholder="-"
            />
            <TextField
                label="City"
                name="city"
                value={formData.city}
                onChange={handleChange}
                fullWidth
                margin="normal"
                disabled={!editMode}
                placeholder="-"
            />

            <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
                <Button variant="contained" color="primary" onClick={toggleEditMode}>
                    {editMode ? 'Cancel' : 'Edit'}
                </Button>
                {editMode && (
                    <Button variant="contained" color="secondary" onClick={handleSave}>
                        Save Changes
                    </Button>
                )}
            </Box>
        </Box>
    );
};

export default Settings;
